import { FormEvent, useContext, useState } from "react";
import { Map } from "../../../model/Game/Globetrotter/Map";
import Dialog from "../../Dialog";
import { Button, SubmitButton } from "../../forms/FormGroup";
import Translate from "../../Helper/Translate";
import MapPreview from "./MapPreview";
import { BulkUpdateLocation, GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import ItemContainer from "../../ModelPreview/ItemContainer";
import { Location } from "../../../model/Game/Globetrotter/Location";
import EditLocationForm from "./EditLocationForm";
import LocationItem from "./LocationItem";
import { GlobetrotterEditingContext } from "../../EditingContext";
import { isFetchError } from "../../../services/FetchHelper";
import MapInteraction from "./MapInteraction";
import AddLocationForm from "./AddLocationForm";
import { insertOrUpdateItem } from "../../../services/CustomFunctions";
import { ModifiedLocation } from "../../../model/Game/Globetrotter/ModifiedLocation";
import { GeneralError } from "../../Error/GeneralError";

interface Props {
    onClose: () => void;
    map: Map;
}

const toModifiedLocation = (location: Location) : ModifiedLocation => ({id: location.id, x: location.x, y: location.y, radius: location.radius});
const getModifiedMap = (map: Map, modifiedLocations: ModifiedLocation[]) => ({ ...map, locations: map.locations.map((x) => ({ ...x, ...modifiedLocations.find(y => x.id === y.id)}))});

// TODO: Change this to update everything in the model, only submit changes when clicking on Submit button
const EditMapForm = (props: Props) => {
    const { game, setGame } = useContext(GlobetrotterEditingContext);
    const { onClose, map } = props;
    
    const [showLocationEdit, setShowLocationEdit] = useState<string>();
    const [moveLocation, setMoveLocation] = useState<Location>();
    const [modifiedLocations, setModifiedLocations] = useState<ModifiedLocation[]>([]);
    const [showAddLocation, setShowAddLocation] = useState<boolean>();

    const [bulkUpdateLocation, loadingBulkUpdate, errorBulkUpdate] = GlobetrotterContext.useBulkUpdateLocation();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (game && game.id && map && map.id) {
            const bulk: BulkUpdateLocation = {
                updateLocations: getModifiedMap(map, modifiedLocations).locations.filter(x => modifiedLocations.some(y => y.id === x.id))
            };

            const result = await bulkUpdateLocation(game.id, map.id, bulk);

            if (!isFetchError(result)) {
                setGame(result);
            }
        }

        onClose();
    }

    const newLocationClick = () => {
        setShowLocationEdit(undefined);
        setShowAddLocation(true);
    }

    const existingLocationClick = (loc: Location) => {
        setShowLocationEdit(loc.id);
    }

    const updateEditLocation = (location: Location) => {
        setShowLocationEdit(undefined);
    }

    const setModifiedMoveLocation = (location: Location) => {
        if (location) {
            setMoveLocation({...location, ...modifiedLocations.find(x => x.id === location.id)});
        }
    }

    const moveLocationClick = (location: Location) => {
        if (location === moveLocation) {
            setMoveLocation(undefined);
        }
        else {
            setModifiedMoveLocation(location);
        }
    }    

    const locationPlaced = (location: Location) => {
        setModifiedLocations(x => insertOrUpdateItem(x, toModifiedLocation(location)));
    }


    const radiusChangedEventHandler = (location: Location, radius: number) => {
        setModifiedLocations(x => insertOrUpdateItem(x, toModifiedLocation({...location, radius})));
    }

   
    return (
        <Dialog
            loading={loadingBulkUpdate}
            onClose={onClose}            
            onClick={() => setMoveLocation(undefined)}
        >
            <h1><Translate id="globetrotter_edit_locations"  data={{name: map.mapName}} /></h1>
            {map &&
                <form className="globetrotter-map-form" onSubmit={onSubmit}>
                    <div className="flex">
                        <div className="globetrotter-map-info-column">
                            <div>
                                <ItemContainer                                    
                                    vertical
                                    className="scroll"
                                    items={map.locations}
                                    heading={<h2><Translate id='globetrotter_locations' /> ({map.locations.length})</h2>}
                                    subheading={<Button className='margin-top' name='globetrotter_add_location' icon={"plus"} onClick={newLocationClick} />}
                                    itemRender={(loc) =>
                                        <LocationItem
                                            isHighlighted={false}
                                            location={loc}
                                            modifiedLocation={modifiedLocations.find(x => x.id === loc.id)}
                                            onRadiusChanged={radiusChangedEventHandler}
                                            onClick={existingLocationClick}
                                            onMoveClick={moveLocationClick}
                                            isMoving={moveLocation === loc} />}
                                />
                            </div>
                        </div>
                        <div>
                            {map?.mapImage &&
                                <>
                                    <label><Translate id="globetrotter_map_preview" /></label>
                                    <div className="map-edit">
                                        <MapPreview className="map-static" map={getModifiedMap(map, modifiedLocations)} hideLocation={moveLocation} onLocationClicked={(l) => setModifiedMoveLocation(l)}></MapPreview>
                                        {moveLocation &&
                                            <MapInteraction location={moveLocation} onPlaced={locationPlaced} onClick={() => setMoveLocation(undefined)} />
                                        }
                                    </div>
                                </>
                            } 
                        </div>
                    </div>
                    <GeneralError error={errorBulkUpdate}/>
                    <SubmitButton split />
                </form>
            }
            {showLocationEdit && game && 
                <EditLocationForm
                    mapId={map.id}
                    onClose={() => { setShowLocationEdit(undefined); setMoveLocation(undefined); }}
                    onSubmit={updateEditLocation}
                    location={getModifiedMap(map, modifiedLocations).locations.find(x => x.id === showLocationEdit)}
                />
            }
            {showAddLocation && game &&
                <AddLocationForm map={map} onClose={() => { setShowAddLocation(false); setMoveLocation(undefined); }} />
            }
        </Dialog>
    )
}

export default EditMapForm;