import React, { useState } from "react";
import { ChangeEvent } from '../../../model/ChangeEvent';
import { Location } from "../../../model/Game/Globetrotter/Location";
import { Button, Slider } from "../../forms/FormGroup";
import { ModifiedLocation } from "../../../model/Game/Globetrotter/ModifiedLocation";
import Translate from "../../Helper/Translate";

interface Props {
    location: Location;
    onClick?: (location: Location) => void;
    onMoveClick?: (location: Location) => void;
    isMoving: boolean;
    onRadiusChanged?: (location: Location, radius: number) => void;
    isHighlighted: boolean;
    modifiedLocation?: ModifiedLocation;
}

const LocationItem = (props: Props) => {
    const { location, modifiedLocation, onClick, onMoveClick, isMoving, onRadiusChanged, isHighlighted } = props;

    const [radius, setRadius] = useState<number>(location.radius);
    const loc = { ...location, ...modifiedLocation };

    const clicked = () => {
        onClick && onClick(location);
    }

    const moveClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onMoveClick && onMoveClick(location);
    }

    const noHints = !loc.hints || loc.hints.length <= 0;

    const changeRadius = (e: ChangeEvent<number>) => {
        setRadius(e.target.value);
        onRadiusChanged && onRadiusChanged(loc, e.target.value);
    }

    return (
        <>
            <div className={`location-item${noHints ? " warning" : ""}${isHighlighted ? " highlight" : ""}`} >
                {noHints &&
                    <label><Translate id="globetrotter_add_at_least_one_hint" /></label>
                }
                <div className="location-item-content" onClick={clicked}>
                    <div >
                        <h3>{loc.name}</h3>
                        <div>
                            {noHints ?
                                <Translate id="globetrotter_no_hints" />
                                :
                                <Translate id="globetrotter_number_of_hints" data={{ numHints: loc.hints.length }} />
                            }
                        </div>
                    </div>
                    {onMoveClick &&
                        <div>
                            <Button onClick={moveClicked}>{isMoving ? <Translate id="globetrotter_stop" /> : <Translate id="globetrotter_move" />}</Button>
                        </div>
                    }
                </div>
                {onRadiusChanged &&
                    <div className="location-radius">
                        <label><Translate id="globetrotter_radius"/></label>
                        <Slider
                            name='radius'
                            value={radius}
                            min={10}
                            max={150}
                            step={2}
                            onChange={e => changeRadius(e)}
                        />
                    </div>
                }

            </div>

        </>
    )
}

export default LocationItem; 