import { DbImage } from "../DbImage";
import { TextType } from "../Game/TextType";

export interface LicenseWarningSettings
{
    id: string;
    organizationId: string;
    warningThresholdDays: number;
    warningThresholdDate: string;
    platformWarningHeading: TextType;
    platformWarningDescription: TextType;
    myfloorWarningText: TextType;
    platformExpiredHeading: TextType;
    platformExpiredDescription: TextType;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactImage: DbImage|undefined;
}

export const replacePlaceHolders = (text: string, daysLeft: number, licenseRef: number, licenseName: string) => {
    return text
        .replaceAll("{daysLeft}", daysLeft.toString())
        .replaceAll("{licenseName}", licenseName)
        .replaceAll("{licenseReference}", licenseRef.toString())
        .replaceAll("{licenseRef}", licenseRef.toString());
}