import { useCallback } from "react";
import { ContextFunc, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { License, LicenseAudit } from "../model/License";
import { PlatformType } from "../model/PlatformType";
import { AdminCreateFloor } from "../model/Request/ActiveFloorLicense/AdminCreateFloor";
import { AdminCreateWall } from "../model/Request/ActiveFloorLicense/AdminCreateWall";
import { AdminUpdateLicense } from "../model/Request/ActiveFloorLicense/AdminUpdateLicense";
import { AdminUpdateWall } from "../model/Request/ActiveFloorLicense/AdminUpdateWall";
import { PaymentType } from "../model/FinancialData";
import { ResellerCreateFloor, ResellerCreateWall } from "../model/Request/ActiveFloorLicense/ResellerCreateLicense";
import { ResellerEditLicense, ResellerEditWall } from "../model/Request/ActiveFloorLicense/ResellerEditLicense";
import { LicenseStatus } from "../model/License/LicenseStatus";
import { ResubscribeRequest } from "../model/Request/ActiveFloorLicense/ResubscribeRequest";
import { Organization } from "../model/Organization";
import { LicenseSearchModel } from "../model/Request/SearchModel";
import { SearchResult } from "../model/Response/SearchResult";
import { LicenseTableItem } from "../model/License/LicenseTableItem";
import { Floor } from "../model/Floor";
import { Wall } from "../model/Wall";
import { RenewalDashboardData } from "../model/License/RenewalDashboard";


export interface ILicenseContext{
    useUpdateToPaymentType: ContextFunc<License, [PlatformType, string, UpdateToPaymentType]>
    useCreateFloorAdmin: ContextFunc<License, [AdminCreateFloor]>;
    useCreateWallAdmin: ContextFunc<License, [AdminCreateWall]>;
    useUpdateFloorAdmin: ContextFunc<License, [string, AdminUpdateLicense]>;
    useUpdateWallAdmin: ContextFunc<License, [string, AdminUpdateWall]>;
    useCreateFloorReseller: ContextFunc<License, [ResellerCreateFloor]>;
    useCreateWallReseller: ContextFunc<License, [ResellerCreateWall]>;
    useUpdateFloorReseller: ContextFunc<License, [string, ResellerEditLicense]>;
    useUpdateWallReseller: ContextFunc<License, [string, ResellerEditWall]>;
    useTermination: ContextFunc<License, [string, PlatformType]>;
    useResub: ContextFunc<License, [string, PlatformType, ResubscribeRequest]>;
    useRemove: ContextFunc<void, [string, PlatformType]>;
    useOrgLicenses: ContextFunc<LicenseTableItem[], [string]>;
    useGetLicenseAudit: ContextFunc<LicenseAudit[], [string]>;
    useStatus: ContextFunc<LicenseStatus, []>;
    useManagerOrg: ContextFunc<Organization, [string]>;
    useSearch: ContextFunc<SearchResult<LicenseTableItem>, [LicenseSearchModel]>;
    useLicense: ContextFunc<Floor|Wall, [PlatformType, string]>;
    useRenewalDashboard: ContextFunc<RenewalDashboardData, [string]>;
}

const baseUrl = "api/license"; 

export const LicenseContext: ILicenseContext = {
    useLicense: () => {
        const [rawInvoke, loading, error] = useFetchGet<Floor|Wall>(); 
        const invoke = useCallback((platformType: PlatformType, id: string) => rawInvoke(`api/${platformType}/${id}`), [rawInvoke]); 
        return [invoke, loading, error]
    },
    useUpdateToPaymentType: () => {
        const [i, l, e] = useFetchPost<License>();
        const invoke = useCallback(
            (p: PlatformType, id: string, model: UpdateToPaymentType) => i(`${baseUrl}/${p}/${id}/updateToPaymentType`, model), [i]
        );
        return [invoke, l, e];
    },
    useCreateFloorReseller: () => {
        const [rawInvoke, loading, error] = useFetchPost<License>();
        const invoke = useCallback((model: ResellerCreateFloor) => rawInvoke(`${baseUrl}/floor/reseller`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreateWallReseller: () => {
        const [rawInvoke, loading, error] = useFetchPost<License>();
        const invoke = useCallback((model: ResellerCreateWall) => rawInvoke(`${baseUrl}/wall/reseller`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateFloorReseller: () => {
        const [rawInvoke, loading, error] = useFetchPut<License>();
        const invoke = useCallback((id: string, model: ResellerEditLicense) => rawInvoke(`${baseUrl}/floor/${id}/reseller`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateWallReseller: () => {
        const [rawInvoke, loading, error] = useFetchPut<License>();
        const invoke = useCallback((id: string, model: ResellerEditWall) => rawInvoke(`${baseUrl}/wall/${id}/reseller`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateFloorAdmin: () => {
        const [rawInvoke, loading, error] = useFetchPut<License>();
        const invoke = useCallback((id: string, model: AdminUpdateLicense) => rawInvoke(`${baseUrl}/floor/${id}/admin`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateWallAdmin: () => {
        const [rawInvoke, loading, error] = useFetchPut<License>();
        const invoke = useCallback((id: string, model: AdminUpdateWall) => rawInvoke(`${baseUrl}/wall/${id}/admin`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreateFloorAdmin: () => {
        const [rawInvoke, loading, error] = useFetchPost<License>();
        const invoke = useCallback((model: AdminCreateFloor) => rawInvoke(`${baseUrl}/floor/admin`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreateWallAdmin: () => {
        const [rawInvoke, loading, error] = useFetchPost<License>();
        const invoke = useCallback((model: AdminCreateWall) => rawInvoke(`${baseUrl}/wall/admin`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useTermination: () => {
        const [rawInvoke, loading, error] = useFetchPost<License>();
        const invoke = useCallback((licenseId: string, platformType: PlatformType) => rawInvoke(`${baseUrl}/${licenseId}/${platformType}/terminatelicense`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useResub: () => {
        const [rawInvoke, loading, error] = useFetchPut<License>();
        const invoke = useCallback(
            (licenseId: string, platformType: PlatformType, model: ResubscribeRequest) => 
                rawInvoke(`${baseUrl}/${platformType}/${licenseId}/resubscribe`, model)
            , [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemove: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((licenseId: string, platform: PlatformType) => rawInvoke(`api/license/${platform}/${licenseId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgLicenses: () => {
        const [rawInvoke, loading, error] = useFetchGet<LicenseTableItem[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`${baseUrl}/byorg/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGetLicenseAudit: () => {
        const [rawInvoke, loading, error] = useFetchGet<LicenseAudit[]>();
        const invoke = useCallback((licenseId: string) => rawInvoke(`${baseUrl}/audit/${licenseId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStatus: () => {
        const [rawInvoke, loading, error] = useFetchGet<LicenseStatus>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/status`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useManagerOrg: () => {
        const [rawInvoke, loading, error] = useFetchGet<Organization>();
        const invoke = useCallback((licenseId: string) => rawInvoke(`${baseUrl}/${licenseId}/managerOrg`),[rawInvoke]);
        return [invoke, loading, error];
    },
    useSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<LicenseTableItem>>();
        const invoke = useCallback((model: LicenseSearchModel) => rawInvoke(`${baseUrl}/search`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRenewalDashboard: () => {
        const [rawInvoke, loading, error] = useFetchGet<RenewalDashboardData>();
        const invoke = useCallback((resellerId: string) => rawInvoke(`${baseUrl}/reseller/${resellerId}/dashboard`), [rawInvoke]);
        return [invoke, loading, error];
    }
}

export interface UpdateToPaymentType{
    paymentType: PaymentType;
    expireDate: string;
}