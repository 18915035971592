import {StfAnswer} from "../model/Game/SmackTheFly/StfAnswer";
import {isDbAudio, isDbDocument, isDbImage} from "./ImageHelper";
import {StfRound} from "../model/Game/SmackTheFly/StfRound";
import {ImageUploadType} from "../model/ImageUploadType";
import {DbImage} from "../model/DbImage";
import {SoundUploadType} from "../model/SoundUploadType";
import {DbSound} from "../model/DbSound";
import {TextType} from "../model/Game/TextType";
import {QnAnswer} from "../model/Game/QuizNinja/QnAnswer";
import {QnRound} from "../model/Game/QuizNinja/QnRound";
import {DjLabSoundClip} from "../model/Game/DjLab/DjLabSoundClip";
import {PictureBookSlide} from "../model/Game/PictureBook/PictureBookSlide";
import {WosQuiz} from "../model/Game/WarOfStrategy/WosQuiz";
import {BuzzItQuiz} from "../model/Game/BuzzIt/BuzzItQuiz";
import {SpinTheBottleRound} from "../model/Game/SpinTheBottle/SpinTheBottleRound";
import {DitjQuiz} from "../model/Game/DangerInTheJungle/DitjQuiz";
import {CombinationElement} from "../model/Game/Combination/CombinationElement";
import {BikeRaceQuiz} from "../model/Game/BikeRace/BikeRaceQuiz";
import {getTextAlign} from "./TextHelper";
import {SakuraItem} from "../model/Game/Sakura/SakuraItem";
import {SakuraGame} from "../model/Game/Sakura/SakuraGame";
import {PuzzleImage} from "../model/Game/JigsawPuzzle/PuzzleImage";
import { FirstWordRequest } from "../model/Request/WordWizard/FirstWordRequest";
import { WordRequest } from "../model/Request/WordWizard/WordRequest";
import { DrumsGame } from "../model/Game/Drums/DrumsGame";
import { GameSettings } from "../api/BaseGameContext";
import { CanvasImage } from "../model/Game/Painter/CanvasImage";

export const createBaseSettingsFormData = (settings: GameSettings):FormData => {
    const form = new FormData();
    form.append("Skin", settings.skin ?? "Default");

    //Document
    const document = settings.document;
    if(document && !isDbDocument(document)){
        form.append("Document", document.file);
    }
    form.append("ReuseDocument", JSON.stringify(!!(document && isDbDocument(document))));
    
    return form;
}

export const appendImageDto = (form: FormData, image: ImageUploadType | DbImage | null | false | undefined, prestring: string): FormData => {
    if (image && !isDbImage(image)){
        form.append(`${prestring}.Base64image`, image.base64Image);
        form.append(`${prestring}.X`, JSON.stringify(image.x));
        form.append(`${prestring}.Y`, JSON.stringify(image.y));
        form.append(`${prestring}.Width`, JSON.stringify(image.width));
        form.append(`${prestring}.Height`, JSON.stringify(image.height));
    }
    return form;
};

export const appendSoundDto = (form: FormData, sound: SoundUploadType | DbSound | null | false | undefined, field: string): FormData => {
    if (sound && !isDbAudio(sound)){
        form.append(field, sound.soundFile);
    }
    return form;
};

export const appendTextDto = (form: FormData, text: TextType, field: string): FormData => {
    form.append(`${field}.Content`, text.content || "");
    form.append(`${field}.FontSize`, JSON.stringify(text.fontSize));
    form.append(`${field}.FontFamily`, text.fontFamily);
    form.append(`${field}.FontColorHex`, text.fontColorHex);
    form.append(`${field}.TextAlignment`, getTextAlign(text));

    return form;
};

export const addPlaylistToFloorFormData = (playlistId: string, row: number, sortIndex: number): FormData  => {
    const form = new FormData();
    form.append("PlayListId", playlistId);
    form.append("RowIndex", row.toString());
    form.append("SortIndex", sortIndex.toString());

    return form;
};

export const addGameToFloorFormData = (gameId: string, row: number, sort: number): FormData  => {
    const form = new FormData();
    form.append("GameId", gameId);
    form.append("RowIndex", row.toString());
    form.append("SortIndex", sort.toString());

    return form;
};

export const createSmackTheFlyAnswerFormData = (answer: StfAnswer): FormData  => {
    let form = new FormData();
    form.append("Text", answer.text || '');
    form.append("font", answer.font);
    form.append("Correct", JSON.stringify(answer.correct));
    form.append("CustomPosition", JSON.stringify(answer.customPosition));
    form.append("X", answer.x.toString());
    form.append("Y", answer.y.toString());
    form.append("Width", answer.width.toString());
    form = appendImageDto(form, answer.image, "Image");
    

    return form;
};

export const updateSmackTheFlyAnswerFormData = (answer: StfAnswer): FormData  => {
    const form = createSmackTheFlyAnswerFormData(answer);
    form.append("ReuseImage", JSON.stringify(!!(answer.image && isDbImage(answer.image))));

    return form;
};

export const createSmackTheFlyRoundFormData = (round: StfRound, gameId?: string): FormData  => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form.append("Hint", round.hint || '');
    form.append("Time", JSON.stringify(round.time || 20));
    form = appendImageDto(form, round.backgroundImage, "BackgroundImage");
    form = appendImageDto(form, round.hintImage, "HintImage");
    form = appendImageDto(form, round.announcerImage, "AnnouncerImage");
    form = appendSoundDto(form, round.hintSound, "HintSound");

    return form;
};

export const updateSmackTheFlyRoundFormData = (round: StfRound, gameId?: string): FormData  => {
    const form = createSmackTheFlyRoundFormData(round, gameId);
    form.append("ReuseBackgroundImage", JSON.stringify(!!(round.backgroundImage && isDbImage(round.backgroundImage))));
    form.append("ReuseHintImage", JSON.stringify(!!(round.hintImage && isDbImage(round.hintImage))));
    form.append("ReuseAnnouncerImage", JSON.stringify(!!(round.announcerImage && isDbImage(round.announcerImage))));
    form.append("ReuseHintSound", JSON.stringify(!!(round.hintSound && isDbAudio(round.hintSound))));

    return form;
};

export const createQuizNinjaAnswerFormData = (answer: QnAnswer): FormData  => {
    let form = new FormData();
    form.append("Text", answer.text || '');
    form.append("font", answer.font);
    form.append("fontColorHex", answer.fontColorHex);
    form.append("Correct", JSON.stringify(answer.correct));
    form = appendImageDto(form, answer.image, "Image");
    
    return form;
};

export const updateQuizNinjaAnswerFormData = (answer: QnAnswer): FormData  => {
    const form = createQuizNinjaAnswerFormData(answer);
    form.append("ReuseImage", JSON.stringify(!!(answer.image && isDbImage(answer.image))));

    return form;
};

export const createQuizNinjaRoundFormData = (round: QnRound, gameId?: string): FormData  => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    } 
    form.append("Title", round.title || '');
    form.append("Description", round.description || '');
    form.append("Question", round.question || '');
    form.append("Time", JSON.stringify(round.time || 20));
    form = appendImageDto(form, round.image, "Image");
    
    return form;
};

export const updateQuizNinjaRoundFormData = (round: QnRound, gameId?: string): FormData  => {
    const form = createQuizNinjaRoundFormData(round, gameId);
    form.append("ReuseImage", JSON.stringify(!!(round.image && isDbImage(round.image))));

    return form;
};

export const createSoundClipFormData = (soundClip: DjLabSoundClip, gameId?: string): FormData  => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    } 
    form.append("BackgroundColor", soundClip.backgroundColor);
    form.append("Position", JSON.stringify(soundClip.position));
    form = appendImageDto(form, soundClip.image, "Image");
    form = appendSoundDto(form, soundClip.sound, "Sound");
    
    return form;
};

export const updateSoundClipFormData = (soundClip: DjLabSoundClip & {index: number}, gameId?: string): FormData  => {
    const form = createSoundClipFormData(soundClip, gameId);
    
    form.append("Index", JSON.stringify(soundClip.index));
    form.append("ReuseImage", JSON.stringify(!!(soundClip.image && isDbImage(soundClip.image))));
    form.append("ReuseSound", JSON.stringify(!!(soundClip.sound && isDbAudio(soundClip.sound))));
    
    return form;
};

export const createSakuraSettingsFormData = (game: SakuraGame): FormData => {
    let form = new FormData();
    form.append("Skin", game.skin || 'Default');
    form = appendImageDto(form, game.backgroundImage, "BackgroundImage");
    form.append("ItemCount", JSON.stringify(game.itemCount));
    form.append("UseCurrentImage", JSON.stringify(!!(game.backgroundImage && isDbImage(game.backgroundImage))));
    form = appendSoundDto(form, game.backgroundSound, "BackgroundSound");
    form.append("UseCurrentBackgroundSound", JSON.stringify(!!(game.backgroundSound && isDbAudio(game.backgroundSound))));
    return form;
};

export const createSakuraItemFormData = (item: SakuraItem, gameId?: string, create?: boolean, index?: number): FormData => {
    let form = new FormData();
    if(gameId){
        form.append("GameId", gameId);
    }
    form = appendImageDto(form, item.image, "Image");
    form = appendSoundDto(form, item.collisionSound, "CollisionSound");
    form.append("Width", JSON.stringify(item.width));

    if(!create){
        form.append("UseCurrentImage", JSON.stringify(!!(item.image && isDbImage(item.image))));
        form.append("UseCurrentCollisionSound", JSON.stringify(!!(item.collisionSound && isDbAudio(item.collisionSound))));
        form.append("Index", JSON.stringify(index));
    }

    return form;
};

export const createJigsawPuzzleImageFormData = (item: PuzzleImage, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form.append("BorderColor", item.borderColor);
    form = appendImageDto(form, item.image, "Image");
    return form;
};

export const updateJigsawPuzzleImageFormData = (item: PuzzleImage & {index: number}, gameId?: string): FormData => {
    const form = createJigsawPuzzleImageFormData(item, gameId);
    form.append("Index", JSON.stringify(item.index));
    form.append("UseCurrentImage", JSON.stringify(!!(item.image && isDbImage(item.image))));

    return form;
};

export const createPainterCanvasImageFormData = (item: CanvasImage, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form = appendImageDto(form, item.image, "Image");
    return form;
};

export const updatePainterCanvasImageFormData = (item: CanvasImage & {index: number}, gameId?: string): FormData => {
    const form = createPainterCanvasImageFormData(item, gameId);
    form.append("Index", JSON.stringify(item.index));
    form.append("UseCurrentImage", JSON.stringify(!!(item.image && isDbImage(item.image))));
    return form;
};

export const createHexcolorFormData = (item: TextType, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form = appendTextDto(form, item, "Hexcolor");
    return form;
};

export const updateHexcolorFormData = (item: TextType & {index: number}, gameId?: string): FormData => {
    const form = createHexcolorFormData(item, gameId);
    form.append("Index", JSON.stringify(item.index));
    form.append("Hexcolor", item.content);
    return form;
};

export const createPicturebookSlideFormData = (slide: PictureBookSlide, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    } 
    form.append("BackgroundColor", slide.backgroundColor);
    form = appendImageDto(form, slide.image, "Image");
    form = appendSoundDto(form, slide.sound, "Sound");
    form.append("AutoPlay", JSON.stringify(slide.autoPlay));
    form.append("LoopSound", JSON.stringify(slide.loopSound));
    
    return form;
};

export const updatePicturebookSlideFormData = (slide: PictureBookSlide & {index: number}, gameId?: string): FormData  => {
    const form = createPicturebookSlideFormData(slide, gameId);
    form.append("Index", JSON.stringify(slide.index));
    form.append("UseCurrentImage", JSON.stringify(!!(slide.image && isDbImage(slide.image))));
    form.append("UseCurrentSound", JSON.stringify(!!(slide.sound && isDbAudio(slide.sound))));
    
    return form;
};

export const createWosQuizFormData = (quiz: WosQuiz, gameId?: string) => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form.append("Difficulty", JSON.stringify(quiz.difficulty));

    form = appendTextDto(form, quiz.question.text, "QuestionText");
    form = appendImageDto(form, quiz.question.image, "QuestionImage");
    form = appendSoundDto(form, quiz.question.sound, "QuestionSound");

    form.append("Answer1Correct", JSON.stringify(quiz.answer1.correct));
    form = appendTextDto(form, quiz.answer1.text,  "Answer1Text");
    form = appendImageDto(form, quiz.answer1.image, "Answer1Image");

    form.append("Answer2Correct", JSON.stringify(quiz.answer2.correct));
    form = appendTextDto(form, quiz.answer2.text, "Answer2Text");
    form = appendImageDto(form, quiz.answer2.image, "Answer2Image");

    form.append("Answer3Correct", JSON.stringify(quiz.answer3.correct));
    form = appendTextDto(form, quiz.answer3.text, "Answer3Text");
    form = appendImageDto(form, quiz.answer3.image,  "Answer3Image");

    return form;
};

export const createWosQuizEditFormData = (quiz: WosQuiz, quizIndex: number, gameId?: string): FormData => {
    const form = createWosQuizFormData(quiz,  gameId);
    form.append("QuizIndex", JSON.stringify(quizIndex));
    form.append("ReuseQuestionSound", JSON.stringify(!!(quiz.question.sound && isDbAudio(quiz.question.sound))));
    form.append("ReuseQuestionImage", JSON.stringify(!!(quiz.question.image && isDbImage(quiz.question.image))));
    form.append("ReuseAnswer1Image", JSON.stringify(!!(quiz.answer1.image && isDbImage(quiz.answer1.image))));
    form.append("ReuseAnswer2Image", JSON.stringify(!!(quiz.answer2.image && isDbImage(quiz.answer2.image))));
    form.append("ReuseAnswer3Image", JSON.stringify(!!(quiz.answer3.image && isDbImage(quiz.answer3.image))));

    return form;
};

export const createBuzzItQuizFormData = (quiz: BuzzItQuiz, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }

    form = appendTextDto(form, quiz.question.text, "QuestionText");
    form = appendImageDto(form, quiz.question.image, "QuestionImage");
    form = appendSoundDto(form, quiz.question.sound, "QuestionSound");

    form.append("Answer1Correct", JSON.stringify(quiz.answer1.correct));
    form = appendTextDto(form, quiz.answer1.text,  "Answer1Text");
    form = appendImageDto(form, quiz.answer1.image, "Answer1Image");

    form.append("Answer2Correct", JSON.stringify(quiz.answer2.correct));
    form = appendTextDto(form, quiz.answer2.text, "Answer2Text");
    form = appendImageDto(form, quiz.answer2.image, "Answer2Image");

    form.append("Answer3Correct", JSON.stringify(quiz.answer3.correct));
    form = appendTextDto(form, quiz.answer3.text, "Answer3Text");
    form = appendImageDto(form, quiz.answer3.image,  "Answer3Image");

    return form;
};

export const updateBuzzItQuizFormData = (quiz: BuzzItQuiz, quizIndex: number, gameId?: string): FormData => {
    const form = createBuzzItQuizFormData(quiz,  gameId);
    form.append("QuizIndex", JSON.stringify(quizIndex));
    form.append("ReuseQuestionSound", JSON.stringify(!!(quiz.question.sound && isDbAudio(quiz.question.sound))));
    form.append("ReuseQuestionImage", JSON.stringify(!!(quiz.question.image && isDbImage(quiz.question.image))));
    form.append("ReuseAnswer1Image", JSON.stringify(!!(quiz.answer1.image && isDbImage(quiz.answer1.image))));
    form.append("ReuseAnswer2Image", JSON.stringify(!!(quiz.answer2.image && isDbImage(quiz.answer2.image))));
    form.append("ReuseAnswer3Image", JSON.stringify(!!(quiz.answer3.image && isDbImage(quiz.answer3.image))));

    return form;
};

export const createBikeRaceQuizFormData = (quiz: BikeRaceQuiz, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId) {
        form.append("GameId", gameId);
    }

    form = appendTextDto(form, quiz.question.text, "QuestionText");
    form = appendImageDto(form, quiz.question.image, "QuestionImage");
    form.append("CorrectAnswer", JSON.stringify(quiz.correctAnswer));

    return form;
};

export const updateBikeRaceQuizFormData = (quiz: BikeRaceQuiz, quizIndex: number, gameId?: string): FormData => {
    const form = createBikeRaceQuizFormData(quiz, gameId);

    form.append("QuizIndex", JSON.stringify(quizIndex));
    form.append("ReuseQuestionImage", JSON.stringify(!!(quiz.question.image && isDbImage(quiz.question.image))));

    return form;
};

export const createSpinTheBottleRound = (round: SpinTheBottleRound, gameId?: string): FormData => {
    let form = new FormData();
    if(gameId){
        form.append("GameId", gameId);
    }
    form = appendTextDto(form, round.text, 'Text');
    form = appendImageDto(form, round.image, 'Image');
    form = appendSoundDto(form, round.audio, 'audio');

    return form;
};

export const updateSpinTheBottleRound = (round: SpinTheBottleRound & {index: number}, gameId?: string): FormData => {
    const form = createSpinTheBottleRound(round, gameId);
    form.append('RoundIndex', JSON.stringify(round.index));
    form.append('ReuseImage', JSON.stringify(!!(round.image && isDbImage(round.image))));
    form.append('ReuseAudio', JSON.stringify(!!(round.audio && isDbAudio(round.audio))));

    return form;
};

export const createDitjQuizFormData = (quiz: DitjQuiz, gameId?: string): FormData => {
    
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form.append("Category", quiz.category);
    
    form = appendTextDto(form, quiz.question.text, "QuestionText");
    form = appendImageDto(form, quiz.question.image, "QuestionImage");
    form = appendSoundDto(form, quiz.question.sound, "QuestionSound");
    
    form.append("Answer1Correct", JSON.stringify(quiz.answer1.correct));
    form = appendTextDto(form, quiz.answer1.text,  "Answer1Text");
    form = appendImageDto(form, quiz.answer1.image, "Answer1Image");

    form.append("Answer2Correct", JSON.stringify(quiz.answer2.correct));
    form = appendTextDto(form, quiz.answer2.text, "Answer2Text");
    form = appendImageDto(form, quiz.answer2.image, "Answer2Image");

    form.append("Answer3Correct", JSON.stringify(quiz.answer3.correct));
    form = appendTextDto(form, quiz.answer3.text, "Answer3Text");
    form = appendImageDto(form, quiz.answer3.image,  "Answer3Image");
    
    return form;
};

export const createDitjQuizEditFormData = (quiz: DitjQuiz, quizIndex: number, gameId?: string): FormData => {
    const form = createDitjQuizFormData(quiz,  gameId);
    form.append("QuizIndex", JSON.stringify(quizIndex));
    form.append("ReuseQuestionSound", JSON.stringify(!!(quiz.question.sound && isDbAudio(quiz.question.sound))));
    form.append("ReuseQuestionImage", JSON.stringify(!!(quiz.question.image && isDbImage(quiz.question.image))));
    form.append("ReuseAnswer1Image", JSON.stringify(!!(quiz.answer1.image && isDbImage(quiz.answer1.image))));
    form.append("ReuseAnswer2Image", JSON.stringify(!!(quiz.answer2.image && isDbImage(quiz.answer2.image))));
    form.append("ReuseAnswer3Image", JSON.stringify(!!(quiz.answer3.image && isDbImage(quiz.answer3.image))));
    
    return form;
};

export const createWordwizardWordFormData = (word: WordRequest): FormData => {
    let form = new FormData();
    form.append('WordToSpell', word.wordToSpell);
    form = appendImageDto(form, word.descriptionImage, `DescriptionImage`);
    form = appendSoundDto(form, word.descriptionSound, 'DescriptionSound');
    form = appendTextDto(form, word.descriptionText, 'DescriptionText');
    return form;
};

export const createWordwizardFirstWordFormData = (word: FirstWordRequest): FormData => {
    const form = createWordwizardWordFormData(word);
    form.append('SelectedAlphabet', word.selectedAlphabet? word.selectedAlphabet.toString() : 'English');
    return form;
};

export const editWordwizardWordFormData = (word: WordRequest): FormData => {
    const form = createWordwizardWordFormData(word);
    form.append(`ReuseWordImage`, JSON.stringify(!!(isDbImage(word.descriptionImage))));
    form.append(`ReuseWordSound`, JSON.stringify(!!(isDbAudio(word.descriptionSound))));
    return form;
};

export const createDrumsGame = (model: DrumsGame): FormData => {
    const form = new FormData();

    return form;
}



export const createCombinationItemFormData = (comboItem: CombinationElement, gameId?: string): FormData => {
    let form = new FormData();
    if (gameId){
        form.append("GameId", gameId);
    }
    form = appendImageDto(form, comboItem.image, 'Image');
    form = appendSoundDto(form, comboItem.sound, 'Sound');
    form = appendTextDto(form, comboItem.text, 'Text');
    return form;
};

export const editCombinationItemFormData = (comboItem: CombinationElement, comboIndex: number, gameId?: string): FormData => {
    const form = createCombinationItemFormData(comboItem, gameId);
    form.append('CombiIndex', JSON.stringify(comboIndex));
    form.append('ReuseImage', JSON.stringify(!!(comboItem.image && isDbImage(comboItem.image))));
    form.append('ReuseSound', JSON.stringify(!!(comboItem.sound && isDbAudio(comboItem.sound))));
    return form;
};