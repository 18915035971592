import { Filters, FrontEndGameFilters, GameFilters } from "./Filters";

export interface OrganziationSearchModel{
    offset: number;
    size: number;
    sort: string;
    ascending: boolean;
    filters: {
        country?: string;
        state?: string;
        city?: string;
        systemName?: string;
        name?: string;
        type?: string;
        categories?: string;
        contact?: string;
        hasFloor?: FilterBool;
        hasWall?: FilterBool;
    }
}

export interface LicenseSearchModel{
    offset: number;
    size: number;
    sort: string;
    ascending: boolean;
    filters: {
        licenseRef?: string;
        paymentType?: string;
        hasRenewalOrder?: FilterBool;
        hasRenewalRequest?: FilterBool; 
        licenseName?: string;
        platformType?: string;
        currency?: string;
        paymentFrequency?: number;
        note?: string;
        resellerNote?: string;
        invoicedOrgSystemName?: string;
        invoicedOrgCountry?: string;
        invoicedOrgState?: string;
        invoicedOrgCity?: string;
        endClientSystemName?: string;
        endClientCountry?: string;
        endClientState?: string;
        endClientCity?: string;
    }
}

type FilterBool = "either"|"include"|"exclude";

export const toFilterBool = (x: string|undefined|true|false): FilterBool => {
    if(typeof x === "string") throw Error("string canot be converted to filter bool");
    if(x === true) return "include";
    if(x === false) return "exclude";
    return "either";
}

export interface SearchModel{
    searchString?: string;
    offset?: number;
    size?: number;
    sort: string;
    ascending: boolean;
}

export interface FrontEndGameSearchModel extends SearchModel{
    gameFilters?: FrontEndGameFilters;
}

export interface GameSearchModel extends FrontEndGameSearchModel{
    gameFilters?: GameFilters; 
}


export const getGameSearchModel = (x: FrontEndGameSearchModel, verifiedContentOnly: boolean): GameSearchModel => {
    const frontEndGameFilters: FrontEndGameFilters = x.gameFilters || {
        gameTypes: [],
        languages: [],
        subjects: [],
        tags: [],
        minAge: 0,
        maxAge: 50
    }; 
    return ({
        ...x, 
        gameFilters: {...frontEndGameFilters, verifiedContentOnly}
    }); 
}

export interface PlaylistSearchModel extends SearchModel{
    filters: Filters;
}