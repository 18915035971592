import { Map } from "../../../model/Game/Globetrotter/Map";
import MapPreview from "./MapPreview";

interface Props {
    map: Map
}

const ReorderMapPreview = (props: Props) => {
    const { map } = props;

    return (
        <div className="reorder-map-preview">
            <h3>{map.mapName}</h3>
            <MapPreview map={map} />
        </div>
    );
}

export default ReorderMapPreview;