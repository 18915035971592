interface GameInfo{
    name: string;
    template: boolean;
}
const SharedGameTypes: GameInfo[] = [
    {name: 'SplatterSpace',     template: false },
    {name: 'Memory',            template: true  },
    {name: 'DangerInTheJungle', template: true  },
    {name: 'PictureBook',       template: true  },
    {name: 'QuizNinja',         template: true  },
    {name: 'SmackTheFly',       template: true  },
    {name: 'BikeRace',          template: true  },
    {name: 'JigsawPuzzle',      template: true  },
    {name: 'BuzzIt',            template: true  },
    {name: 'WarOfStrategy',     template: true  }, 
    {name: 'SuperSorter',       template: true  },
    {name: 'Presentation',      template: true  },
    {name: 'PopIt',             template: true  },
    {name: 'WordFinder',        template: true  },
    {name: 'PizzaParty',        template: false },
];

const MyWallOnlyTypes: GameInfo[] = [
    {name: 'SweetyWar',     template: false },
    {name: 'CrazyBalloons', template: false },
    {name: 'SlimeBattle',   template: false },
    {name: 'BlockBuster',   template: false },
    {name: 'CircusRace',    template: false },
    {name: 'GoalShow',      template: false },
    {name: 'WhackAMole',    template: false },
    {name: 'Meditation',    template: false },
    {name: 'RaceCar',       template: false },
    {name: 'FeedTheFox',    template: false },
    {name: 'DanceASloth',   template: false },
    {name: 'MadMonsters',   template: false },
];

const MyFloorOnlyTypes: GameInfo[] = [
    {name: 'Airhockey',     template: false },
    {name: 'AwesomeForest', template: false },
    {name: 'BrickOut',      template: false },
    {name: 'Butterflies',   template: false },
    {name: 'Chess',         template: false },
    {name: 'DragonDice',    template: false },
    {name: 'Drums',         template: false },
    {name: 'Fishes',        template: false },
    {name: 'Garden',        template: false },
    {name: 'SimonSays',     template: false },
    {name: 'Soccer',        template: false },
    {name: 'GalacticGolf',  template: false },
    {name: 'WallBall',      template: false },
    {name: 'Combination',   template: true  },
    {name: 'DJLab',         template: true  },
    {name: 'TvLounge',      template: true  },
    {name: 'SpinTheBottle', template: true  },
    {name: 'Sakura',        template: true  },
    {name: 'WordWizard',    template: true  },
    {name: 'Painter',       template: true  },
    {name: 'Ordering',      template: true  },
    {name: 'Geekster',      template: false }, //It is a template, but we don't want users to be able to create it, so template = false
    {name: 'EscapeRoom',    template: true }, 
    {name: 'Globetrotter',  template: true } 
];

export const AllGameTypes = [...SharedGameTypes.map(x => x.name), ...MyFloorOnlyTypes.map(x => x.name), ...MyWallOnlyTypes.map(x => x.name)] as const;
export type GameType = typeof AllGameTypes[number];

export const GameTypesMyFloor: GameType[] = [...SharedGameTypes.map(x => x.name), ...MyFloorOnlyTypes.map(x => x.name)];
export const GameTypesMyFloorStatic: GameType[] = [...SharedGameTypes.filter(x => !x.template).map(x => x.name), ...MyFloorOnlyTypes.filter(x => !x.template).map(x => x.name)];
// exclude EscapeRoom for now
export const GameTypesMyFloorTemplate: GameType[] = [...SharedGameTypes.filter(x => x.template).map(x => x.name), ...MyFloorOnlyTypes.filter(x => x.template && x.name !== 'EscapeRoom').map(x => x.name)];

export const GameTypesMyWall: GameType[] = [...SharedGameTypes.map(x => x.name), ...MyWallOnlyTypes.map(x => x.name)];
export const GameTypesMyWallStatic: GameType[] = [...SharedGameTypes.filter(x => !x.template).map(x => x.name), ...MyWallOnlyTypes.filter(x => !x.template).map(x => x.name)];
export const GameTypesMyWallTemplate: GameType[] = [...SharedGameTypes.filter(x => x.template).map(x => x.name), ...MyWallOnlyTypes.filter(x => x.template).map(x => x.name)];



export type StaticGameType = typeof GameTypesMyFloorStatic[number];     