import React, { useContext, useEffect, useState } from "react";
import { Location } from "../../../model/Game/Globetrotter/Location";
import { GlobetrotterEditingContext } from "../../EditingContext";
import PinElement from "./PinElement";
import Translate from "../../Helper/Translate";

interface Props {
    onClick?: () => void;
    location: Location;
    onPlaced?: (location: Location) => void;
}

const MapInteraction = (props: Props) => {
    const { onClick, location, onPlaced } = props;

    const [active, setActive] = useState<boolean>();
    const [mousePos, setMousePos] = useState<{ x: number, y: number }>();
    const [model, setModel] = useState<Location>(location);

    const { game } = useContext(GlobetrotterEditingContext);

    useEffect(() => {
        setModel(x => ({...location, x:x.x,y:x.y}));
    }, [location]);

    const clicked = async () => {
        if (mousePos && location && game && onPlaced) { 
            const cl = { ...location, x: mousePos.x, y: mousePos.y };
            onPlaced(cl);
        }
        setMousePos(undefined);
        setActive(false);
        onClick && onClick();
    }

    const handleMouseMove = (e: React.MouseEvent) => {        
        setMousePos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
        setModel({...model, x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY})
    };

    if (!location) {
        return null;
    }

    return (
        <div className="map-interactive" onClick={clicked} onMouseMove={handleMouseMove} onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
            {active ?
                <>                   
                    {mousePos &&
                        <>
                            <p className="info">{mousePos?.x}, {mousePos?.y}</p>
                            <div className="line vertical" onMouseMove={(e: React.MouseEvent) => e.preventDefault()} style={{
                                top: `${mousePos?.y}px`,
                            }} />
                            <div className="line horizontal" onMouseMove={(e: React.MouseEvent) => e.preventDefault()} style={{
                                left: `${mousePos?.x}px`,
                            }} />
                            <PinElement location={model} placeHolderName="New location" showAsActive/>
                        </>
                    }
                </>
                :
                <div className="place-location-description">
                     <h2><Translate id="globetrotter_click_on_map" data={{name: location.name}}/></h2>
                </div>
            }
        </div>
    )
}

export default MapInteraction;