import React, {useState} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import Dropzone, { FileRejection } from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import {Loading} from "../../Loading";
import {FormButton, CheckBox, SubmitButton, Button} from "../FormGroup";
import {ImageUploadType} from '../../../model/ImageUploadType';
import Translate from '../../Helper/Translate';

interface Props {
    onSubmit: (img: ImageUploadType) => void;
    finalImageSettings: FixedAspectSettings|VariableAspectSetings;
    cropSelectionSettings: {
        initialFixedAspect: boolean; 
        disableFixedAspectCheckbox: boolean
    };
}

interface Size{
    width: number;
    height: number;
}

const isAllowedAspect = (crop: Crop, settings: FixedAspectSettings|VariableAspectSetings) => {
    if(cropHasRectangle(crop)){
        const cropAspect = crop.width/crop.height;
        if(isFixedSettings(settings)){
            const finalImageAspect = settings.maxSize.width/settings.maxSize.height;
            return Math.abs(cropAspect - finalImageAspect) < 0.015;
        }
        else{
            return cropAspect >= settings.minAspect && cropAspect <= settings.maxAspect;
        }
    }
    return false;
}

const cropHasRectangle = (c: undefined|Crop|Rectangle): c is Rectangle => {
    return !!c && c.x !== undefined && c.y !== undefined && c.width !== undefined && c.height !== undefined;
}

const cropToRectangle = (c: Crop, scale: number): Rectangle|undefined => {
    if(!cropHasRectangle(c)) return undefined;
    return {
        x: Math.floor(c.x * scale),
        y: Math.floor(c.y * scale),
        width: Math.floor(c.width * scale),
        height: Math.floor(c.height * scale)
    }
}

const adjustCropToAspect = (img: HTMLImageElement, currentCrop: Rectangle, aspect: number): Crop => {
    const {x,y,width,height} = currentCrop;
    //calculate which is nearest axis needs the least adjustment to make the crop fit the aspect
    const newWidthIfheightUnchanged = calcWidth(height, aspect);
    const newHeightIfWidthUnchanged = calcHeight(width, aspect);
    const shouldCorrectWidth = (currentCrop.width - newWidthIfheightUnchanged) < (height - newHeightIfWidthUnchanged);

    let w = !shouldCorrectWidth ? width : newWidthIfheightUnchanged;
    let h = shouldCorrectWidth ? height : newHeightIfWidthUnchanged;

    //if the width is wider than the image scale down the crop untill it can fit
    if(w > img.width){
        w = img.width;
        h = calcHeight(w, aspect);
    }
    if(h > img.height){
        h = img.height;
        w = calcWidth(h, aspect);
    }

    //if the new crop overflows the image, we need to move the crop back into the img
    const overflowX = x + w - img.width;
    const overflowY = y + h - img.height;

    return {
        x: overflowX > 0 ? x - overflowX : x,
        y: overflowY > 0 ? y - overflowY : y,
        width: w, 
        height: h,
        aspect: w/h,
        unit: "px"
    };
}

export const getMaxCrop = (img: HTMLImageElement|undefined, aspectRatio: undefined|number): Rectangle => {
    if(!img) return {x: 0, y: 0, width: 1, height: 1};

    if (aspectRatio) {
        //calculate new cut
        let height = img.height;
        let width = img.height * aspectRatio;

        while (width >= img.width) {
            height = height - 1;
            width = calcWidth(height, aspectRatio);
        }

        //center the crop
        const x = Math.floor((img.width - width) / 2);
        const y = Math.floor((img.height - height) / 2);
        return {x, y, width, height};
    }

    return {
        x: 0,
        y: 0,
        height: img.height,
        width: img.width
    };
}

const calcHeight = (width: number, aspect: number) => Math.round(width / aspect);
const calcWidth = (height: number, aspect: number) => Math.round(height * aspect);

const getCanvasSizeWithFixedSettings = (settings: FixedAspectSettings, crop: Rectangle): {canvasSize: Size, cropDrawSize: Partial<Size>} => {
    if(isAllowedAspect(crop, settings)){
        return{
            canvasSize: {width: Math.min(crop.width, settings.maxSize.width), height: Math.min(crop.height, settings.maxSize.height)},
            cropDrawSize: {width: Math.min(crop.width, settings.maxSize.width)}
        };
    }
    else{
        const {maxSize} = settings;
        const {width, height} = maxSize;
        const aspect = width / height;
        
        if(crop.width < width && crop.height < height){
            const dstToWidth = Math.abs(crop.width - width);
            const dstToHeight = Math.abs(crop.height - height);
            let canvasWidth, canvasHeight, previewWidth, previewHeight = 0;
            if(dstToWidth < dstToHeight){
                canvasWidth = crop.width;
                canvasHeight = calcHeight(canvasWidth, aspect);
                previewWidth = crop.width;
                return {canvasSize: {width: canvasWidth, height: canvasHeight}, cropDrawSize: {width: previewWidth}};
            }
            else{
                canvasHeight = crop.height;
                canvasWidth = calcWidth(canvasHeight, aspect);
                previewHeight = canvasHeight;
                return {canvasSize: {width: canvasWidth, height: canvasHeight}, cropDrawSize: {height: previewHeight}};
            }
        }
        else{
            const cropAspect = crop.width / crop.height;
            if(cropAspect > aspect){
                return {canvasSize: {width, height}, cropDrawSize: {width}};
            }
            else{
                return {canvasSize: {width, height}, cropDrawSize: {height}};
            }
        }
    }
}

const getCanvasSizeWithVariableSettings = (settings: VariableAspectSetings, crop: Rectangle): {canvasSize: Size, cropDrawSize: Partial<Size>} => {
    if(isAllowedAspect(crop, settings)){
        const {maxSize: size, constrainAxis} = settings;
        if(
            (constrainAxis === 'width' && crop.width < size) ||
            (constrainAxis === 'height' && crop.height < size) 
        ){
            return {
                canvasSize: {width: crop.width, height: crop.height}, 
                cropDrawSize: {width: crop.width}
            };
        }
        else{
            const aspect = crop.width/crop.height;
            if(constrainAxis === 'width'){
                return {
                    canvasSize: {width: size, height: calcHeight(size, aspect)}, 
                    cropDrawSize: {width: size}
                };
            }
            else{
                return {
                    canvasSize: {width: calcWidth(size, aspect), height: size}, 
                    cropDrawSize: {height: size}
                };
            }
        }
    }
    else{
        const {maxSize: size, constrainAxis, minAspect, maxAspect} = settings;
        const cropAspect = crop.width / crop.height;
        const aspect = cropAspect < minAspect ? minAspect : maxAspect;
        let canvasWidth, canvasHeight = 0;
        if(constrainAxis === "width"){
            canvasWidth = Math.min(crop.width, size);
            canvasHeight = calcHeight(canvasWidth, aspect);
        }
        else{//constraint === "height"
            canvasHeight = Math.min(crop.height, size);
            canvasWidth = calcWidth(canvasHeight, aspect);
        }

        if(cropAspect > minAspect){
            return{
                canvasSize: {width: canvasWidth, height: canvasHeight},
                cropDrawSize: {width: canvasWidth}
            };
        }
        else{
            return{
                canvasSize: {width: canvasWidth, height: canvasHeight},
                cropDrawSize: {height: canvasHeight}
            };
        }
    }
}

const getImageSizes = (settings: FixedAspectSettings|VariableAspectSetings, crop: Rectangle): {canvasSize: Size, cropDrawSize: Size} => { 
    const cropAspect = crop.width / crop.height;
    const {canvasSize, cropDrawSize} = isFixedSettings(settings) 
        ? getCanvasSizeWithFixedSettings(settings, crop) 
        : getCanvasSizeWithVariableSettings(settings, crop);

    const {width: pw, height: ph} = cropDrawSize;

    if(pw && ph) return {canvasSize, cropDrawSize: {width: pw, height: ph}};
    else if(!pw && ph) return {canvasSize, cropDrawSize: {width: calcWidth(ph, cropAspect), height: ph}};
    else if(!ph && pw) return {canvasSize, cropDrawSize: {width: pw, height: calcHeight(pw, cropAspect)}};
    
    throw new Error("Something went wrong determining crop size");
}

export const getPreviewBlob = (image: HTMLImageElement, settings: FixedAspectSettings|VariableAspectSetings, crop: Rectangle): string => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx || !image) return '';

    const unrenderedImage = new Image();
    unrenderedImage.src = image.src;
    
    const {canvasSize, cropDrawSize: previewSize} = getImageSizes(settings, crop);
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    ctx.drawImage(
        unrenderedImage,
        Math.floor(crop.x),
        Math.floor(crop.y),
        Math.floor(crop.width),
        Math.floor(crop.height),
        Math.floor((canvasSize.width - previewSize.width) / 2),
        Math.floor((canvasSize.height - previewSize.height) / 2),
        Math.floor(previewSize.width),
        Math.floor(previewSize.height)
    );

    return canvas.toDataURL('image/png');
}


const ImageForm = (props: Props) => {
    const {onSubmit, finalImageSettings, cropSelectionSettings} = props;

    const [base64ImgBlob, setBase64Imgblob] = useState<string>();
    const [image, setImage] = useState<HTMLImageElement>(); 
    const [cropBox, setCrop] = useState<Rectangle>();
    const [error, setError] = useState<string>();
    const [base64Loading, setBase64Loading] = useState(false);

    const submit = () => {
        if (cropBox && base64ImgBlob && image){
            const data : ImageUploadType = {
                base64Image: base64ImgBlob,
                x: cropBox.x,
                y: cropBox.y,
                width: cropBox.width,
                height: cropBox.height,
                previewBlob: getPreviewBlob(image, finalImageSettings, cropBox),
                salt: Math.random()
            };
            //Make sure startpoint is inside img
            data.x = Math.max(data.x, 0);
            data.y = Math.max(data.y, 0);
            //Make sure width and height doesn't exceed image width/height
            const maxWidthFromX = image.naturalWidth - data.x;
            const maxHeightFromY = image.naturalHeight - data.y;
            data.width = Math.min(data.width, maxWidthFromX);
            data.height = Math.min(data.height, maxHeightFromY);
            onSubmit(data);
        }
    };

    const onImageLoaded = (image: HTMLImageElement) => setImage(image);

    const fileDropped = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        
        if (rejectedFiles.length) {
            setError(rejectedFiles[0].errors.length ? rejectedFiles[0].errors[0].code : "unknown_file_upload_error");
        }
        else{
            setError(undefined);
        }
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                if(target.result){
                    setBase64Imgblob(target.result as string);
                    setBase64Loading(false);
                }
            };
            reader.readAsDataURL(file);
            setBase64Loading(true);
        }
    };

    return (
        <div className='image-crop-form'>
            <Loading visible={base64Loading}/>
            <h2><Translate id='image'/></h2>
            <div className='drop-container'>
                <div>
                    <Dropzone onDrop={fileDropped} accept={['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp']}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                                <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                    <input {...getInputProps()} />
                                    <p><Translate id='dropzone_upload_text' /></p>
                                </div>
                            )
                        }}
                    </Dropzone>
                    {error && 
                        <div className='form-error-container'>
                            <div className='form-error'>
                                <Translate 
                                    id={error==='file-invalid-type' ? 'file_invalid_type_placeholder' : error} 
                                    data={error==='file-invalid-type' ? {formats: "'.jfif', '.pjpeg', '.jpeg', '.pjp', '.jpg', '.png', '.gif', '.bmp', 'webp'"} : undefined}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='crop-container'>
                {base64ImgBlob &&
                    <ImageCropper 
                        onLoad={onImageLoaded} 
                        src={base64ImgBlob} 
                        onChange={setCrop} 
                        finalImageSettings={finalImageSettings}
                        cropAspectFixed={cropSelectionSettings.initialFixedAspect}
                        disableCropAspectFixedCheckbox={cropSelectionSettings.disableFixedAspectCheckbox} 
                    />
                }
            </div>
            {image && cropBox &&
                <>
                    <h2><Translate id='preview' /></h2>
                    <div className='final-image-preview'>
                        <img src={getPreviewBlob(image, finalImageSettings, cropBox)} alt='preview' className='checkered' />
                    </div>
                </>
            }
            <SubmitButton split text={'submit'} onClick={submit} disabled={!cropBox || !base64ImgBlob} />
        </div>

    );
}

export default ImageForm;

export interface Rectangle{
    x: number;
    y: number;
    width: number;
    height: number;
}
export interface FixedAspectSettings{
    maxSize: Size;
}
export interface VariableAspectSetings{
    minAspect: number;
    maxAspect: number;
    maxSize: number;
    constrainAxis: 'width'|'height'; // Which axis should have size as its max size.
}

export const isFixedSettings = (settings: FixedAspectSettings|VariableAspectSetings): settings is FixedAspectSettings => {
     return isSize(settings.maxSize);
}
export const isSize = (size: number|Size): size is Size => {
    return typeof(size) === "object" && "width" in size && "height" in size;
}
 
interface ImageCropperProps{
    src: string;
    onChange: (crop?: Rectangle) => void;
    onLoad: (image: HTMLImageElement) => void;
    finalImageSettings: FixedAspectSettings|VariableAspectSetings;
    cropAspectFixed:  boolean;
    disableCropAspectFixedCheckbox: boolean;
}

const ImageCropper = (props: ImageCropperProps) => {
    const {src, onChange, onLoad: _onLoad, cropAspectFixed, disableCropAspectFixedCheckbox, finalImageSettings} = props;
    const [crop, setCrop] = useState<Crop>();
    const [image, setImage] = useState<HTMLImageElement>();

    const cropChange = (c: Crop) => {
        if(!image) return;
        setCrop(oc => oc && ({...oc, ...c, aspect: oc.aspect ? (c.width??1)/(c.height??1) : undefined}));
        const rect = cropToRectangle(c, image.naturalWidth / image.width);
        onChange(rect);
    }

    const onImageLoaded = (image: HTMLImageElement) => {
        setImage(image);
        const maxRect = getMaxCrop(image, isFixedSettings(finalImageSettings) ? finalImageSettings.maxSize.width/finalImageSettings.maxSize.height : undefined);
        const c: Crop = {
            ...maxRect,
            aspect: cropAspectFixed ? maxRect.width / maxRect.height : undefined
        }
        setCrop(c);
        onChange(cropToRectangle(c, image.naturalWidth / image.width));
        _onLoad(image);
        return false;
    };

    const setMaxCrop = () => {
        const c = getMaxCrop(image, isFixedSettings(finalImageSettings) ? finalImageSettings.maxSize.width/finalImageSettings.maxSize.height : undefined);
        cropChange(c);
    }

    const setCropAspectFixed = (b: boolean) => {
        setCrop(c => c && ({...c, aspect: cropHasRectangle(c) && b ? c.width/c.height : undefined}));
        if(b) conformCropToAspect();
    };

    const conformCropToAspect = () => {
        if(image && cropHasRectangle(crop)){
            if(!isAllowedAspect(crop, finalImageSettings)){
                if(isFixedSettings(finalImageSettings)){
                    cropChange(adjustCropToAspect(image, crop, finalImageSettings.maxSize.width/finalImageSettings.maxSize.height));
                }
                else{
                    const {minAspect, maxAspect} = finalImageSettings;
                    const cropAspect = crop.width/crop.height;
                    const dstToMin = Math.abs(minAspect - cropAspect);
                    const dstToMax = Math.abs(maxAspect - cropAspect);
                    cropChange(adjustCropToAspect(image, crop, dstToMin < dstToMax ? minAspect : maxAspect));
                }
            }
        }
    }

    return(
        <>
            <CheckBox 
                name={'fixedAspect'} 
                label={'image_fixedAspect'} 
                disabled={disableCropAspectFixedCheckbox} 
                active={crop?.aspect !== undefined} 
                onChange={e => setCropAspectFixed(e.target.value)}
            />
            <Button 
                name='image_conform_to_aspect_interval' 
                disabled={!cropHasRectangle(crop) || isAllowedAspect(crop, finalImageSettings)} 
                disabledHoverMsg='image_conform_to_aspect_interval_disabled'
                onClick={conformCropToAspect} 
            />
            <FormButton onClick={setMaxCrop} name={'image_setmax'} />
            <ReactCrop 
                minHeight={10} 
                minWidth={10} 
                keepSelection 
                src={src} 
                onChange={cropChange}  
                crop={crop}
                onImageLoaded={onImageLoaded} 
            />
        </>
    ) 
}