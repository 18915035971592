import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import {Loading} from "../../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ImageUploadType} from '../../../model/ImageUploadType';
import { FixedAspectSettings, getMaxCrop, getPreviewBlob, isFixedSettings, VariableAspectSetings } from './ImageForm';

interface Props {
    onSubmit: (img: ImageUploadType) => void;
    finalImageSettings: FixedAspectSettings|VariableAspectSetings;
}

const QuickImage = (props: Props) => {
    const {onSubmit, finalImageSettings} = props;

    const [base64ImgBlob, setBase64Imgblob] = useState<string>();
    const [base64Loading, setBase64Loading] = useState(false);
   
    const onImageLoaded = (image: HTMLImageElement) => {
        const cropBox = getMaxCrop(image, isFixedSettings(finalImageSettings) ? finalImageSettings.maxSize.width/finalImageSettings.maxSize.height : undefined);
        if (base64ImgBlob && image){
            const data : ImageUploadType = {
                base64Image: base64ImgBlob,
                x: cropBox.x,
                y: cropBox.y,
                width: cropBox.width,
                height: cropBox.height,
                previewBlob: getPreviewBlob(image, finalImageSettings, cropBox),
                salt: Math.random()
            };
            //Make sure startpoint is inside img
            data.x = Math.max(data.x, 0);
            data.y = Math.max(data.y, 0);
            //Make sure width and height doesn't exceed image width/height
            const maxWidthFromX = image.naturalWidth - data.x;
            const maxHeightFromY = image.naturalHeight - data.y;
            data.width = Math.min(data.width, maxWidthFromX);
            data.height = Math.min(data.height, maxHeightFromY);
            onSubmit(data);
            setBase64Loading(false);
        }
    };

    const fileDropped = (acceptedFiles: File[]) => {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                if(target.result){
                    setBase64Imgblob(target.result as string);
                    setBase64Loading(false);
                }
            };
            reader.readAsDataURL(file);
            setBase64Loading(true);
        }
    };

    return (
        <div className='quick-upload'>
            <Loading visible={base64Loading}/>
            <div className='drop-container'>
                <Dropzone onDrop={fileDropped} accept={['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/jpe', 'image/webp']}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                <input {...getInputProps()}/>
                                <FontAwesomeIcon icon='upload' />
                            </div>
                        )
                    }}
                </Dropzone>
                {base64ImgBlob &&
                    <ReactCrop src={base64ImgBlob} onChange={() => {}} onImageLoaded={onImageLoaded} style={{display: 'none'}} />
                }  
            </div>
        </div>
    );

}

export default QuickImage;