import { useCallback, useState } from "react";
import { GlobetrotterGame } from "../../../model/Game/Globetrotter/GlobetrotterGame";
import EditGameComponent from "../../EditGameComponent";
import { GameSettings } from "../../../api/BaseGameContext";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import CreateMapForm from "./CreateMapForm";
import { Map } from "../../../model/Game/Globetrotter/Map";
import ItemContainer from "../../ModelPreview/ItemContainer";
import EditMapForm from "./EditMapForm";
import { GlobetrotterEditingContext } from "../../EditingContext";
import MapControl from "./MapControl";
import Translate from "../../Helper/Translate";
import { Button } from "../../forms/FormGroup";
import ReorderMapsForm from "./ReorderMapsForm";

const Globetrotter = () => {
    const [game, setGame] = useState<GlobetrotterGame>();
    const [showMapForm, setShowMapForm] = useState<boolean>();
    const [editMap, setEditMap] = useState<Map>();
    const [showReorderMaps, setShowReorderMaps] = useState<boolean>();

    const newElementFunc = useCallback(() => setShowMapForm(true), []);

    const mapsWithoutLocations = game?.maps.filter(x => x.locations === undefined || x.locations.length === 0);
    const mapsWithMissingHints = game?.maps.filter(x => x.locations.some(l => l.hints === undefined || l.hints.length === 0));
    const mapsWithLessThan4Locations = game?.maps.filter(x => x.locations?.length < 4);

    console.log(mapsWithLessThan4Locations);

    return (
        <GlobetrotterEditingContext.Provider value={{ game: game, setGame: setGame }}>
            <EditGameComponent<GlobetrotterGame, GameSettings>
                game={game}
                setGame={setGame}
                gameContext={GlobetrotterContext}
                tempDescription={'globetrotter_temp_text'}
                tempTitle={'globetrotter_create_title'}
                contentClass={"globetrotter"}
                publishRequirements={[
                    {
                        name: 'globetrotter_maps_required',
                        done: (game?.maps.length ?? 0) > 0,
                        action: newElementFunc
                    },
                    {
                        name: 'globetrotter_locations_required',
                        done: mapsWithoutLocations?.length === 0,
                        action: () => mapsWithoutLocations && setEditMap(mapsWithoutLocations[0])
                    },
                    {
                        name: 'globetrotter_hints_required',
                        done: mapsWithMissingHints?.length === 0,
                        action: () => mapsWithMissingHints && setEditMap(mapsWithMissingHints[0])
                    }
                ]}
                publicRequirements={[
                    {
                        name: 'globetrotter_4_locations_per_map_required',
                        done: mapsWithLessThan4Locations?.length === 0,

                    },
                    {
                        name: 'globetrotter_hints_required',
                        done: mapsWithMissingHints?.length === 0,
                        action: () => mapsWithMissingHints && setEditMap(mapsWithMissingHints[0])
                    }
                ]}
                newGameElementFunction={newElementFunc}
                newGameElementDisabled={game?.maps && game.maps.length >= 5}
                settingsMapper={g => ({ skin: g.skin ?? "Default", document: g.document })}
            >
                <h1><Translate id="gametype_Globetrotter" /></h1>

                {game &&
                    <ItemContainer
                        items={game.maps}
                        heading={
                            <div>
                                <h2><Translate id="globetrotter_maps" /></h2>
                                { game.maps.length > 1 && <Button disabled={game.deleted} onClick={() => setShowReorderMaps(true)}><Translate id="globetrotter_reorder_maps" /></Button> }
                            </div>
                        }
                        itemRender={item =>
                            <MapControl map={item} game={game} />
                        }

                    />}

                {showMapForm &&
                    <CreateMapForm
                        map={null}
                        gameId={game?.id}
                        onClose={() => setShowMapForm(false)}
                    />
                }
                {
                    editMap && editMap.id &&
                    <EditMapForm
                        map={editMap}
                        onClose={() => setEditMap(undefined)}
                    />
                }
                {
                    showReorderMaps && 
                    <ReorderMapsForm onClose={() => setShowReorderMaps(false)}/>
                }
            </EditGameComponent>
        </GlobetrotterEditingContext.Provider>
    )
}

export default Globetrotter;