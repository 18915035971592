import { useCallback, useState } from "react";
import { BaseGame } from "../../model/Game/BaseGame";
import { GameStats } from "../../model/GameStats";
import { GameSearchModel } from "../../model/Request/SearchModel";
import { SearchResult } from "../../model/Response/SearchResult";
import { ContextFunc, ContextReturn, useFetchDelete, useFetchGet, useFetchPost } from "../../services/FetchHelper";
import { GameType } from "../../model/Game/GameType";
import { TransferGames } from "../../model/Request/TransferGames";
import { getToken } from "../../services/LocalStorageService";

interface IGameContext{
    useGetCopies: ContextFunc<BaseGame[], [string]>;
    useMyGames: ContextFunc<BaseGame[], []>;
    useAuthorGames: ContextFunc<BaseGame[], []>;
    useBaseGame: ContextFunc<BaseGame, [string]>;
    useManyBaseGames: ContextFunc<BaseGame[], [string[]]>;
    useOrganizationGameSearch: ContextFunc<SearchResult<BaseGame>, [string, GameSearchModel]>;
    useByFloor: ContextFunc<{[key: string]: BaseGame}, [string]>;
    useSearch: ContextFunc<SearchResult<BaseGame>, [GameSearchModel]>;
    useSearchCsv: ContextFunc<void, [GameSearchModel, string?]>;
    useStats: ContextFunc<GameStats, [string]>;
    useMyLanguageGames: ContextFunc<BaseGame[], []>;
    useMyLanguageVerifiedGames: ContextFunc<BaseGame[], []>,
    useMyLanguageGamesEdit: ContextFunc<BaseGame[], []>;
    useStaticGame: ContextFunc<BaseGame[], []>;
    usePublicGames: ContextFunc<BaseGame[], [number]>;
    useGamesFromFollowlist: ContextFunc<BaseGame[], []>;
    useAccountPopularGames: ContextFunc<BaseGame[], [string]>;
    useAllAccountGames: ContextFunc<BaseGame[], [string]>;
    useDeletedGames: ContextFunc<BaseGame[], []>;
    useRestoreGame: ContextFunc<void, [GameType, string]>;
    usePermDelete: ContextFunc<void, [GameType, string]>;
    usePermDeleteAll: ContextFunc<void, []>;
    useMany: ContextFunc<BaseGame[], [string[]]>;
    useCustomersGames: ContextFunc<BaseGame[], [string]>;
    useGamesMatchingSubjects: ContextFunc<BaseGame[], []>; 
    useOwnedGames: ContextFunc<BaseGame[], [string]>;
    useChangeownerBulk: ContextFunc<void, [TransferGames]>;
    useOrgGameCsv: ContextFunc<void, []>;
}

const baseUrl = 'api/game'

export const GameContext: IGameContext = {
    useGetCopies: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}/copies`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/mygames`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAuthorGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/mygames/author`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useBaseGame: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame>();
        const invoke = useCallback((gameId: string) => rawInvoke(`${baseUrl}/${gameId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useManyBaseGames: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((gameIds: string[]) => rawInvoke(`${baseUrl}/many`, gameIds), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrganizationGameSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<BaseGame>>();
        const invoke = useCallback((orgId: string, model: GameSearchModel) => rawInvoke(`${baseUrl}/organization/${orgId}/search`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useByFloor: () => {
        const [rawInvoke, loading, error] = useFetchPost<{ [key: string]: BaseGame; }>();
        const invoke = useCallback((apiKey: string) => rawInvoke(`${baseUrl}/byfloor`, { id: apiKey }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<BaseGame>>();
        const invoke = useCallback((model: GameSearchModel) => rawInvoke(`api/library/search/games`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStats: () => {
        const [rawInvoke, loading, error] = useFetchGet<GameStats>();
        const invoke = useCallback((gameId: string) => rawInvoke(`api/game/${gameId}/stats`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageVerifiedGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage/verified'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMyLanguageGamesEdit: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/myLanguage/edit'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStaticGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/static'), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePublicGames: () => {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((count: number) => rawInvoke(`api/game/public/${count}`, {}), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGamesFromFollowlist: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke('api/game/followlist'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAccountPopularGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`api/game/account/${accountId}/popular`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAllAccountGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`api/game/account/${accountId}/all`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOwnedGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`api/game/account/${accountId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDeletedGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`api/game/mygames/deleted`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRestoreGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((gameType: GameType, id: string) => rawInvoke(`api/workshop/${gameType}/${id}/restore`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePermDelete: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((gameType: GameType, id: string) => rawInvoke(`api/workshop/${gameType}/${id}/perm`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePermDeleteAll: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback(() => rawInvoke(`api/game/mygames/deleted/perm`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMany: function (): ContextReturn<BaseGame[], [string[]]> {
        const [rawInvoke, loading, error] = useFetchPost<BaseGame[]>();
        const invoke = useCallback((ids: string[]) => rawInvoke('api/game/many', ids), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCustomersGames: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`api/game/byCustomers/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    }, 
    useGamesMatchingSubjects: () => {
        const [rawInvoke, loading, error] = useFetchGet<BaseGame[]>();
        const invoke = useCallback(() => rawInvoke(`api/game/matchingsubjects`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useChangeownerBulk: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((model: TransferGames) => rawInvoke(`api/game/transfer/bulk`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgGameCsv: () => {
        const [loading, setLoading] = useState(false);
        const fetchCsv = useCallback(async () => {
            setLoading(true);
            fetch('/api/game/csv/export/org', {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Accept': 'application/json, text/plain, */*'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "org_games_export.csv";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                })
                .then(() => setLoading(false));
        },[]);
        
        return [fetchCsv, loading, undefined];
    },
    useSearchCsv: () => {
        const [loading, setLoading] = useState(false);
        const fetchCsv = useCallback(async (model: GameSearchModel, orgId?: string) => {
            setLoading(true);
            fetch(orgId ? `/api/game/search/csv?orgId=${orgId}` : '/api/game/search/csv', {
                method: 'post',
                body: JSON.stringify(model),
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "search_games_export.csv";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                })
                .then(() => setLoading(false));
        },[])
        
        return [fetchCsv, loading, undefined];
    },
}