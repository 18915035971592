import MapPreview from "./MapPreview";
import { Map } from "../../../model/Game/Globetrotter/Map";
import { Button } from "../../forms/FormGroup";
import { useState } from "react";
import CreateMapForm from "./CreateMapForm";
import EditMapForm from "./EditMapForm";
import { GlobetrotterGame } from "../../../model/Game/Globetrotter/GlobetrotterGame";
import Translate from "../../Helper/Translate";

interface Props {
    map: Map;
    game: GlobetrotterGame;
    onClick?: (map: Map) => void;
}

const MapControl = (props: Props) => {
    const { map, game } = props;

    const [showMapDetails, setShowMapDetails] = useState<boolean>();
    const [showEditMap, setShowEditMap] = useState<boolean>();

    return (
        <div>
            <h3><Translate id="globetrotter_number_of_locations" data={{mapName: map.mapName, numLocations: map.locations.length}}/></h3>
            <div className="flex">
                <Button disabled={game.deleted} onClick={() => setShowMapDetails(true)}><Translate id="globetrotter_edit_map_details_button" /></Button>
                <Button disabled={game.deleted} onClick={() => setShowEditMap(true)}><Translate id="globetrotter_edit_locations_button" /></Button>
            </div>
            <MapPreview map={map} />
            {showMapDetails &&
                <CreateMapForm map={map} gameId={game.id} onClose={() => setShowMapDetails(false)} />
            }
            {showEditMap &&
                <EditMapForm
                    map={map}
                    onClose={() => setShowEditMap(false)}
                />
            }            
        </div>
    )
}

export default MapControl; 