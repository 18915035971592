import { FormEvent, useContext, useState } from "react";
import Dialog from "../../Dialog";
import { GlobetrotterEditingContext } from "../../EditingContext";
import Translate from "../../Helper/Translate";
import ItemContainer from "../../ModelPreview/ItemContainer";
import Sortable from "../../DragnDrop";
import { Map } from "../../../model/Game/Globetrotter/Map";
import { dragNdropOrder } from "../../../services/CustomFunctions";
import ReorderMapPreview from "./ReorderMapPreview";
import { GeneralError } from "../../Error/GeneralError";
import { SubmitButton } from "../../forms/FormGroup";
import { isFetchError } from "../../../services/FetchHelper";
import { GlobetrotterContext, ReorderMaps } from "../../../api/Game/GlobetrotterContext";

interface Props {
    onClose: () => void;
}

const getSortId = (c: Map) => c.id;

const ReorderMapsForm = (props: Props) => {
    const { onClose } = props;
    const { game, setGame } = useContext(GlobetrotterEditingContext);
    const [elements, setElements] = useState<Map[]>(game?.maps ?? []);
    const [reorderMaps, loadingReorder, errorReorder] = GlobetrotterContext.useReorderMaps();

    if (!game?.maps) {
        return null;
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requestmodel: ReorderMaps = { mapIds: elements.map(x => x.id) };
        const result = game && await reorderMaps(game.id, requestmodel);

        if (!isFetchError(result)) {
            setGame(result);
        }

        onClose();
    }

    return (
        <Dialog
            onClose={onClose}
            loading={loadingReorder}
            >
            <h1><Translate id="globetrotter_reorder_maps" /></h1>
            <form onSubmit={onSubmit}>
                <ItemContainer
                    heading={<h2><Translate id="globetrotter_reorder_maps_explain" /></h2>}
                    items={elements}
                    itemRender={m =>
                        <Sortable
                            key={getSortId(m)}
                            id={getSortId(m)}
                            onSort={(s, t) => setElements(e => e && dragNdropOrder(s, t, getSortId, e))}
                            onSortCommit={() => {/* Reorder submitted with dialog-submit instead of end of drag */}}
                            type={'item'}
                            notDraggable={game.deleted}
                        >
                            <ReorderMapPreview map={m} />
                        </Sortable>}
                />
                <GeneralError error={errorReorder} />
                <SubmitButton split disabled={game.deleted} />
            </form>
        </Dialog>
    );
}

export default ReorderMapsForm;